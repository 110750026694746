import Head from 'next/head'
import React from 'react'
import { useTranslation } from 'hooks/useTranslation'
import { HomeAlternate } from 'features/Home/screens/HomeAlternate'
import Script from 'next/script'
export default function HomePage() {
  const { t } = useTranslation()

  // prettier-ignore
  const schema = {
    '@context': 'https://schema.org',
    '@type': 'WebPage',
    name: 'We Feed Raw',
    url: 'https://wefeedraw.com/',
    description: 'Perfectly Balanced Raw Meals, Delivered.',
    inLanguage: 'en',
    mainEntity: {
      '@type': 'Organization',
      name: 'We Feed Raw',
      logo: 'https://wefeedraw.com/assets/isotype-background-brown.svg',
      url: 'https://wefeedraw.com',
      contactPoint: [
        {
          '@type': 'ContactPoint',
          telephone: '+1-843-732-2849',
          contactType: 'Customer Service',
          availableLanguage: ['English'],
          contactOption: 'Text'
        },
        {
          '@type': 'ContactPoint',
          telephone: '+1-854-203-1608',
          contactType: 'Customer Service',
          availableLanguage: ['English']
        },
        {
          '@type': 'ContactPoint',
          email: 'hello@wefeedraw.com',
          contactType: 'Customer Service',
          availableLanguage: ['English']
        }
      ],
      sameAs: [
        'https://www.facebook.com/wefeedraw',
        'https://www.instagram.com/wefeedraw',
        'https://x.com/WeFeedRawFood',
        'https://www.tiktok.com/@wefeedraw',
        'https://www.youtube.com/channel/UCKE0vcmVUzfXlzudtoes19A'
      ]
    },
    about: {
      '@type': 'WebSite',
      name: 'We Feed Raw',
      url: 'https://wefeedraw.com'
    }
  };

  const jsonLdString = JSON.stringify(schema)

  return (
    <div>
      <Head>
        <title>{t('home:meta-title')}</title>
        <meta name="title" content={t('home:meta-title')} />
        <meta name="description" content={t('home:meta-description')} />
        <link rel="icon" href="/favicon.ico" />
        <link rel="canonical" href="https://wefeedraw.com/" />
      </Head>
      <Script
        id="schema-script"
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: jsonLdString }}
      />
      <HomeAlternate />
    </div>
  )
}
